import Head from 'next/head';
import dynamic from 'next/dynamic';
import React from 'react';

import { NextPage } from 'next';

import { StoreState } from 'state/store/reducer';
import { LinearProgress } from '@material-ui/core';
import { NoSsr } from '@material-ui/core';
import i18n from '../i18n';
import { subscriptionPrice } from '../src/core/utils/utils';

const AppDynamic = dynamic<any>(() => import('../src/App').then(mod => mod.App), {
    ssr: false,
    loading: () => <LinearProgress />,
});

interface Props {
    store: StoreState;
}

const Index: NextPage<Props> = ({ store }) => {
    const { t } = i18n.useTranslation();
    return (
        <>
            <Head>
                <title>any.market</title>
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content={t('common:gallery.description')} />
                <meta name="og:title" content={t('common:search.og.title')} />
                <meta
                    name="og:description"
                    content={t('common:search.og.description')}
                />
                <meta name="og:url" content="https://any.market" />
                <meta property="og:image" content="https://any.market/og3.jpg" />
            </Head>
            <NoSsr>
                <AppDynamic />
            </NoSsr>
        </>
    );
};

export default i18n.withTranslation(['auth'])(Index);
